import React, { Suspense, useState } from "react";

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components/auth/PrivateRoute";
import { LoggedInState, useAuthenticated } from "./components/auth/useAuthenticated";
import { PlanLevel } from "./components/application/SetNewAccount/SetPlan/gql/SetPlanLevel.generated";
import { Box, Flex } from "rebass";
import { GlobalErrorMessage } from "./components/GlobalError/GlobalErrorMessage";

const SignUpContainer = React.lazy(() => import("./components/auth/signUp"));
const LogInContainer = React.lazy(() => import("./components/auth/logIn"));
const ApplicationContainer = React.lazy(() => import("./components/application"));
const ResetPassword = React.lazy(() => import("./components/auth/forgotPassword"));

export type Domain = {
  domain: string;
  domainSet: boolean;
};

export type StringOrUndefined = string | undefined | null;

export type DomainToTraffic = {
  domain: string;
  domainSet: boolean;
};

export const UserContext = React.createContext({
  domain: "",
  setDomain: (val: string) => {},
  domainToTraffic: undefined as StringOrUndefined, // Domain + subdomain. Is undefined when creating account
  setDomainToTraffic: (val: StringOrUndefined) => {},
  dnsProperlySetUp: false, // Does the domain Easy DP sends traffic to resolve to the right cname?
  setDNSProperlySetUp: (val: () => boolean) => {},
  hasViewedHelp: true, // Set if the user has viewed the help screen when they create an account
  setHasViewedHelp: (val: boolean) => {},
  // The plan associated with the domain
  plan: [] as DomainAndPlan[],
  setPlan: (val: DomainAndPlan[]) => {},
  domainsAssociatedWithAccount: [] as string[],
  setDomainsAssociatedWithAccount: (val: string[]) => {},
  globalErrorMessage: "",
  setGlobalErrorMessage: (val: string) => {},
  beta: false,
  setBeta: (val: boolean) => {},
  // There are permissions for each domain, de-normalizing the data we get this:
  canInviteUsersOnTheseDomains: [""],
  setCanInviteUsersOnTheseDomains: (val: string[]) => {},
  canViewBillingOnTheseDomains: [""],
  setCanViewBillingOnTheseDomains: (val: string[]) => {},
  canInviteUsersOnCurrentDomain: false,
  setCanInviteUsersOnCurrentDomain: (val: boolean) => {},
  canViewBillingOnCurrentDomain: false,
  setCanViewBillingOnCurrentDomain: (val: boolean) => {},
});

export type DomainAndPlan = {
  plan: PlanLevel;
  domain: string;
};

function App() {
  const isAuthenticated = useAuthenticated();
  const [domain, setDomain] = useState<string>("");
  const [domainToTraffic, setDomainToTraffic] = useState<string | undefined | null>(undefined);
  const [dnsProperlySetUp, setDNSProperlySetUp] = useState(false);
  const [hasViewedHelp, setHasViewedHelp] = useState(true);
  const [plan, setPlan] = useState<DomainAndPlan[]>([]);
  const [domainsAssociatedWithAccount, setDomainsAssociatedWithAccount] = useState([] as string[]);
  const [globalErrorMessage, setGlobalErrorMessage] = useState("");
  const [contentLocalized, setContentLocalized] = useState(false);
  const [deviceDiscerning, setDeviceDiscerning] = useState(false);
  const [beta, setBeta] = useState(false);
  const [canInviteUsersOnTheseDomains, setCanInviteUsersOnTheseDomains] = useState([""]);
  const [canViewBillingOnTheseDomains, setCanViewBillingOnTheseDomains] = useState([""]);
  const [canInviteUsersOnCurrentDomain, setCanInviteUsersOnCurrentDomain] = useState(false);
  const [canViewBillingOnCurrentDomain, setCanViewBillingOnCurrentDomain] = useState(false);

  if (isAuthenticated === LoggedInState.checking) {
    return <></>;
  }

  const userValue = {
    globalErrorMessage,
    setGlobalErrorMessage,
    domain,
    setDomain,
    domainToTraffic,
    setDomainToTraffic,
    dnsProperlySetUp,
    setDNSProperlySetUp,
    hasViewedHelp,
    setHasViewedHelp,
    plan,
    setPlan,
    domainsAssociatedWithAccount,
    setDomainsAssociatedWithAccount,
    contentLocalized,
    deviceDiscerning,
    setDeviceDiscerning,
    beta,
    setBeta,
    canInviteUsersOnTheseDomains,
    setCanInviteUsersOnTheseDomains,
    canViewBillingOnTheseDomains,
    setCanViewBillingOnTheseDomains,
    canInviteUsersOnCurrentDomain,
    setCanInviteUsersOnCurrentDomain,
    canViewBillingOnCurrentDomain,
    setCanViewBillingOnCurrentDomain,
  };

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/sign-up">
            <Suspense fallback={<div></div>}>
              <SignUpContainer />
            </Suspense>
          </Route>
          <Route exact path="/log-in">
            <Suspense fallback={<div></div>}>
              <LogInContainer />
            </Suspense>
          </Route>
          <Route exact path="/password-reset">
            <Suspense fallback={<div></div>}>
              <ResetPassword />
            </Suspense>
          </Route>
          <PrivateRoute isAuth={isAuthenticated} path="/app">
            <UserContext.Provider value={userValue}>
              {globalErrorMessage && <GlobalErrorMessage />}
              <Suspense fallback={<div />}>
                <Box>
                  <ApplicationContainer />
                </Box>
              </Suspense>
            </UserContext.Provider>
          </PrivateRoute>
          <Route path="*">
            {isAuthenticated ? <Redirect to="/app/domain/manage-domain" /> : <Redirect to="/log-in" />}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
