import { fontSize, Theme } from "styled-system";
import { colors } from "./modules/Colors";
import { breakpoints } from "./modules/Breakpoints";
import { typography } from "./modules/Typography";

// Reference https://styled-system.com/table
export const theme: Theme = {
  colors,
  breakpoints,
  fonts: { ...typography },
  radii: {
    default: "8px",
  },
  lineHeights: {
    standard: "140%",
  },
  shadows: {
    big: "0px 16px 18px rgba(0, 88, 220, 0.05), 0px 4px 10px rgba(37, 56, 86, 0.15);",
    small: "0px 8px 18px rgba(0, 88, 220, 0.05), 0px 4px 3px rgba(37, 56, 86, 0.1);",
  },
};
