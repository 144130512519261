import React, { useContext, useEffect } from "react";
import { Flex, Image } from "rebass";
import { Paragraph } from "../primitive/Paragraph";
import { helpers } from "../../theme/GeneratedStyles";
import closeButton from "../shared/icons/close-icon-white-bg.png";
import { UserContext } from "../../App";

export const GlobalErrorMessage = () => {
  const { globalErrorMessage, setGlobalErrorMessage } = useContext(UserContext);
  const unsetError = () => {
    setGlobalErrorMessage("");
  };

  return (
    <Flex
      mb={"50px"}
      bg={"red200"}
      alignItems="center"
      justifyContent={"center"}
      width={"100%"}
      sx={{ position: "fixed", zIndex: "5000" }}
    >
      <Paragraph
        px={"16px"}
        color={"gray900"}
        fontWeight={"500"}
        fontSize={helpers.BodyFont}
        textAlign={"center"}
      >
        {globalErrorMessage}
      </Paragraph>
      <Image
        sx={{ cursor: "pointer" }}
        src={closeButton}
        width={"26px"}
        height={"26px"}
        onClick={() => unsetError()}
      />
    </Flex>
  );
};
