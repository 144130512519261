import React from "react";
import {Redirect, Route} from "react-router-dom";
import {LoggedInState} from "./useAuthenticated";


export const PrivateRoute = ({isAuth, children, ...rest}: any) => {
        return (
            <Route {...rest}
            render={({location}) =>
            isAuth === LoggedInState.authenticated ? (
                children
            ) : (
                <Redirect
                to={{pathname: "/log-in", state: {from: location}}}
                />
            )}/>
        )
};