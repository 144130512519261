import "./wydr";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations as ApmIntegrations } from "@sentry/apm";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import "./theme/normalize.css";
import { ThemeProvider } from "emotion-theming";
import { ThemeProvider as ThemeUiProvider } from "theme-ui";
import { GlobalStyles } from "./theme/GlobalStyles";
import { theme } from "./theme";

let host = process.env.REACT_APP_API_HOST;

if (host === undefined) {
  host = "";
}

let token = null;
if (document.getElementsByName("gorilla.csrf.Token")[0]) {
  token = document.getElementsByName("gorilla.csrf.Token")[0].nodeValue;
}
if (token === null) {
  token = "";
}

export const client = new ApolloClient({
  uri: `${host}/graphql`,
  cache: new InMemoryCache(),
  credentials: "include",
  headers: {
    // "X-CSRF-Token": "MTU5NzA3NzU0OXxJbFZwUnpsa2J5ODVaM3AzYkVvNVFsSkJlR1J4U3lzelozTk9kRzFLYTJacWVIcEpiMUZzY1hsWlJWazlJZ289fGTt4sntMAp6DHV-7Ed2K_yrE9fCXM4Xcij-tOAf8_UY"
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <ThemeUiProvider theme={theme}>
          <App />
        </ThemeUiProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
