export const helpers ={
  Stat: { xs: 36, sm: 40, md: 44, lg: 48, xl: 48 },
  StatArray: [ '36px', '36px', '40px', '44px', '48px', '48px' ],
  MainTitle: { xs: 28, sm: 30, md: 34, lg: 36, xl: 36 },
  MainTitleArray: [ '28px', '28px', '30px', '34px', '36px', '36px' ],
  tertiaryTitle: { xs: 22, sm: 24, md: 26, lg: 28, xl: 28 },
  tertiaryTitleArray: [ '22px', '22px', '24px', '26px', '28px', '28px' ],
  SubTitle: { xs: 20, sm: 21, md: 23, lg: 24, xl: 24 },
  SubTitleArray: [ '20px', '20px', '21px', '23px', '24px', '24px' ],
  BodyFont: { xs: 17, sm: 18, md: 18, lg: 19, xl: 19 },
  BodyFontArray: [ '17px', '17px', '18px', '18px', '19px', '19px' ],
  SmallFont: { xs: 15, sm: 15, md: 16, lg: 16, xl: 16 },
  SmallFontArray: [ '15px', '15px', '15px', '16px', '16px', '16px' ],
  space12: { xs: 9, sm: 10, md: 11, lg: 12, xl: 12 },
  space12Array: [ '9px', '9px', '10px', '11px', '12px', '12px' ],
  space24: { xs: 18, sm: 20, md: 22, lg: 24, xl: 24 },
  space24Array: [ '18px', '18px', '20px', '22px', '24px', '24px' ],
  space32: { xs: 24, sm: 26, md: 30, lg: 32, xl: 32 },
  space32Array: [ '24px', '24px', '26px', '30px', '32px', '32px' ],
  space40: { xs: 24, sm: 31, md: 41, lg: 48, xl: 48 },
  space40Array: [ '24px', '24px', '31px', '41px', '48px', '48px' ],
  space54: { xs: 32, sm: 39, md: 47, lg: 54, xl: 54 },
  space54Array: [ '32px', '32px', '39px', '47px', '54px', '54px' ],
  space64: { xs: 48, sm: 53, md: 59, lg: 64, xl: 64 },
  space64Array: [ '48px', '48px', '53px', '59px', '64px', '64px' ],
  space100: { xs: 64, sm: 75, md: 89, lg: 100, xl: 100 },
  space100Array: [ '64px', '64px', '75px', '89px', '100px', '100px' ]
}