import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
html{
background: #EDF2F7;;
}
body{
font-family:  'Work Sans', sans-serif;
background: #EDF2F7;
background-color: #EDF2F7 !important;
line-height: 220%;
}
code {
background-color: #e4e6e8;
padding: 2px 4px;
}
button{
:focus{
border: none;
outline: none;
}
}
input{
border: none;
outline: none;
:active{
border: none;
outline: none;
}
:focus{
border: none;
outline: none;
}
}
`;
