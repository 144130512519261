// aliases
export const breakpoints = ["0", "640px", "1024px", "1367px", "1960px"];
(breakpoints as any).xs = breakpoints[0];
(breakpoints as any).sm = breakpoints[1];
(breakpoints as any).md = breakpoints[2];
(breakpoints as any).lg = breakpoints[3];
(breakpoints as any).xl = breakpoints[4];

export const xs = breakpoints[0]; // 0px
export const sm = breakpoints[1]; // 640px
export const md = breakpoints[2]; // 1024px
export const lg = breakpoints[3]; // 1367px
export const xl = breakpoints[4]; // 1960px

export default {
  breakpoints,
};
