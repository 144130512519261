export const colors = {
  //  Preset Tailwind
  gray100: "#F7FAFC",
  gray200: "#EDF2F7",
  gray300: "#E2E8EF",
  gray400: "#CBD5DF",
  gray500: "#A0AEBF",
  gray600: "#718095",
  gray700: "#4A5567",
  gray700Hover: "#4f5e77",
  gray800: "#2D3747",
  gray800Hover: "#1E293C",
  gray900: "#1A202B",
  gray900Hover: "#172235",
  blue50: "#EEF6FA",
  blue100: "#EBF8FE",
  blue300: "#8ECDF1",
  blue500: "#4099DD",
  blue500Hover: "#73C6FF",
  blue800: "#2C527F",
  blue900: "#2A4363",
  teal100: "#E5FEFA",
  teal500: "#2BB0AC",
  teal600: "#279694",
  teal500Hover: "#2CBBB7",
  red200: "#FFD8D7",
  red300: "#FFB4B3",
  red500: "#F56565",
  red600: "#E53E3E",
  red700: "#C83935",
  red800: "#9D322F",
  green100: "#EFFFF5",
  green200: "#C3F5D7",
  green300: "#9AE6B4",
  green400: "#68D391",
  green500: "#3BB97D",
  green800: "#24664B",
  // brand
  brandPurple: "#8c7fdc",
  brand600: "#00C5D2",
};
