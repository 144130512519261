// import styled from "styled-components";
import styled from "@emotion/styled";
import * as CSS from "csstype";
import {
  color,
  compose,
  fontFamily,
  fontSize,
  FontSizeProps,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  TextColorProps,
  textStyle,
  TextStyleProps,
} from "styled-system";

interface ParagraphInterface
  extends TextColorProps,
    FontWeightProps,
    TextStyleProps,
    SizeProps,
    SpaceProps,
    FontSizeProps,
    FontStyleProps,
    TextAlignProps,
    LineHeightProps {
  // @ts-ignore
  color?: CSS.ColorProperty;
  textDecoration?: string;
}

export const Paragraph = styled.p<ParagraphInterface>`
  line-height: 140%;
  color: ${(props: any) => props.theme.colors.gray800};
  text-decoration: ${(props: any) => props.textDecoration && props.textDecoration};
  ${compose(color, space, size, fontSize, fontFamily, fontWeight, textAlign, lineHeight, textStyle)}
`;
