import { useMeQueryQuery } from "./meQuery.generated";

export enum LoggedInState {
  checking,
  authenticated,
  notAuthenticated,
  error,
}

export const useAuthenticated = () => {
  // TODO: Refetch queries
  // refetchQueries only updates fetches with a cached network policy
  // fetchmore might be the thing
  const { data, loading, error } = useMeQueryQuery({
    fetchPolicy: "cache-and-network",
  });
  if (loading) {
    return LoggedInState.checking;
  }
  if (data !== undefined) {
    // @ts-ignore
    if (data.me === true) {
      return LoggedInState.authenticated;
    } else {
      // @ts-ignore
      if (data.me === "false") {
        return LoggedInState.notAuthenticated;
      } else if (error !== undefined) {
        return LoggedInState.error;
      }
    }
  }
};
